<template>
  <v-col class="px-0 py-0 detailRoutePageText">
    <div class="cardBackround">
      <v-main>
        <v-container>
          <page-step
            :pages="[
              {
                id: 1,
                name: `Маршрут Коломия-Прага`,
                link: '/route/Kolomiya-Praga/2',
              },
            ]"
          />
          <h2
            class="routeName"
            style="margin-top: 20px"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            {{ route?.departure?.translations?.name }}-{{
              route?.destination?.translations?.name
            }}
          </h2>
          <v-row no-gutters justify="center">
            <search-ticket-form
              style="margin-top: 20px"
              :departure_city="route.departure.id"
              :arrival_city="route.destination.id"
            />
          </v-row>
        </v-container>
      </v-main>
    </div>
    <v-main>
      <v-container>
        <Loader v-if="showLoader" />
        <v-row v-else no-gutters align="start" style="margin-top: 20px">
          <v-col cols="12" xl="7" lg="7" md="7" sm="12" class="px-0 py-0">
            <h4 class="detailRoutePageSubtitle">
              {{ "about_route_label" | localize }}
            </h4>
            <v-row no-gutters align="center">
              <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                :style="
                  $vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px'
                "
              >
                <div class="routeInfoCard">
                  <div class="distanceIcon" style="margin-right: 12px" />
                  <v-col class="px-0 py-0">
                    <span>{{ "total_distance_label" | localize }}</span>
                    <p style="font-weight: 400; margin: 4px 0px 0px 0px">
                      {{ route?.total_distance }} км.
                    </p>
                  </v-col>
                </div>
              </v-col>
              <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                :style="
                  $vuetify.breakpoint.smAndDown ? '' : 'padding-left: 10px'
                "
              >
                <div class="routeInfoCard">
                  <div class="timeIcon" style="margin-right: 12px" />
                  <v-col class="px-0 py-0">
                    <span>{{ "time_on_road_label" | localize }}</span>
                    <p style="font-weight: 400; margin: 4px 0px 0px 0px">
                      {{ route?.total_time }}
                    </p>
                  </v-col>
                </div>
              </v-col>
              <!-- <v-col cols="12">
                <div class="routeInfoCard">
                  <div class="phoneIcon" style="margin-right: 12px" />
                  <v-col class="px-0 py-0">
                    <span>{{ "addintional_info_by_phone" | localize }}</span>
                    <v-row no-gutters align="center">
                      <p
                        style="font-weight: 400; margin: 4px 20px 0px 0px"
                        v-for="i in 3"
                        :key="i"
                      >
                        +38 067 306 93 95
                      </p>
                    </v-row>
                  </v-col>
                </div>
              </v-col> -->
            </v-row>
            <h4 class="detailRoutePageSubtitle" style="margin-top: 40px">
              {{ "detail_route_label" | localize }}
            </h4>
            <detail-route-form
              :routeList="route.cities"
              :isRoute="true"
              style="margin-top: 20px"
            />
            <h3
              class="routeName"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'margin-bottom: 20px; margin-top: 40px;font-size: 24px;'
                  : 'margin-bottom: 40px; margin-top: 90px'
              "
            >
              {{ "nearest_routes_label" | localize }}
            </h3>
            <ticket-search-card
              v-for="ticket in tickets"
              :key="ticket.id"
              :trip="ticket"
            />
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="12" class="px-0 py-0">
            <route-map
              :start_point="{
                lat: parseFloat(route?.cities?.[0]?.station?.city.lat),
                lng: parseFloat(route?.cities?.[0]?.station?.city.lng),
              }"
              :finish_point="{
                lat: parseFloat(
                  route?.cities?.[route.cities.length - 1]?.station?.city.lat
                ),
                lng: parseFloat(
                  route?.cities?.[route.cities.length - 1]?.station?.city.lng
                ),
              }"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-col>
</template>

<script>
import routesService from "../../../../requests/Admin/routesService";
import searchRoutesService from "../../../../requests/User/searchRoutesService";
import TicketSearchCard from "../../../UI/Cards/ticketSearchCard.vue";
import DetailRouteForm from "../../../UI/Forms/detailRouteForm.vue";
import searchTicketForm from "../../../UI/Forms/searchTicketForm.vue";
import PageStep from "../../../UI/pageStep.vue";
import RouteMap from "./routeMap.vue";
import Loader from "../../../UI/Loader.vue";
export default {
  components: {
    PageStep,
    searchTicketForm,
    TicketSearchCard,
    DetailRouteForm,
    RouteMap,
    Loader,
  },
  data: () => ({
    route: {},
    tickets: [],
    showLoader: true,
  }),
  mounted() {
    this.getRoute();
  },
  methods: {
    async getRoute() {
      await routesService
        .getRouteBySlug(this.$route.params.route_slug)
        .then((res) => {
          if (res.status == "Success") {
            this.route = res.data;
            this.searchTicket();
          }
        });
    },
    async searchTicket() {
      await searchRoutesService
        .searchTrip(
          this.route.departure.id,
          this.route.destination.id,
          new Date().toISOString().substring(0, 10),
          1
        )
        .then((res) => {
          if (res.status == "Success") {
            this.tickets = [...res.data.current_date, ...res.data.other_date];
            this.tickets = this.tickets.map(
              (ticket) =>
                (ticket = {
                  ...ticket,
                  arrival_stations: ticket.arrival_stations?.[0],
                  departure_stations: ticket.departure_stations?.[0],
                })
            );
          }
        });
      this.showLoader = false;
    },
  },
};
</script>

<style>
.cardBackground {
  padding: 20px 0px;
  background: #fff;
}
.detailRoutePageText {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-style: normal;
  line-height: normal;
}
.routeName {
  font-size: 32px;
  font-weight: 600;
}
.detailRoutePageSubtitle {
  font-size: 20px;
  font-weight: 700;
}
.routeInfoCard {
  border-radius: 10px;
  background: #f6f8fc;
  padding: 12px 16px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.distanceIcon {
  width: 24px;
  height: 21.5px;
  background-color: #144fa9;
  -webkit-mask: url("../../../../assets/img/iconsSvg/distanceIcon.svg")
    no-repeat center;
  mask: url("../../../../assets/img/iconsSvg/distanceIcon.svg") no-repeat center;
}
.timeIcon {
  width: 24px;
  height: 21.5px;
  background-color: #144fa9;
  -webkit-mask: url("../../../../assets/img/iconsSvg/timeIcon.svg") no-repeat
    center;
  mask: url("../../../../assets/img/iconsSvg/timeIcon.svg") no-repeat center;
}
.phoneIcon {
  width: 24px;
  height: 21.5px;
  background-color: #144fa9;
  -webkit-mask: url("../../../../assets/img/iconsSvg/phoneIcon.svg") no-repeat
    center;
  mask: url("../../../../assets/img/iconsSvg/phoneIcon.svg") no-repeat center;
}
</style>