<template>
  <detail-page-component />
</template>

<script>
import detailPageComponent from '../../components/User/Routes/DetailPage/detailPageComponent.vue'
export default {
  components: { detailPageComponent },
  mounted(){
    this.$vuetify.goTo(0,0)
  },
}
</script>

<style>

</style>