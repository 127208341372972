<template>
  <div>
    <div id="map_canvas"></div>
    <GmapMap
      :class="$vuetify.breakpoint.xs ? '' : 'ml-5'"
      :center="{
        lat: parseFloat(start_point.lat),
        lng: parseFloat(start_point.lng),
      }"
      :zoom="12"
      map-type-id="roadmap"
      :style="
        $vuetify.breakpoint.xs ? 'width: 100%; height: 500px;' : 'height: 760px'
      "
      id="map"
      ref="mapRef"
      class="map"
    >
      <GmapMarker
        :position="{
          lat: parseFloat(start_point.lat),
          lng: parseFloat(start_point.lng),
        }"
        :clickable="true"
      />
      <GmapMarker
        :position="{
          lat: parseFloat(finish_point.lat),
          lng: parseFloat(finish_point.lng),
        }"
        :clickable="true"
      />
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
export default {
  props: {
    start_point: {
      require: true,
    },
    finish_point: {
      require: true,
    },
  },
  mounted() {
    this.setMap();
  },
  methods: {
    setMap() {
      this.$refs.mapRef.$mapPromise.then((map) => {
        let loc = {};
        console.log(this.start_point,this.finish_point)
        let bounds = new this.google.maps.LatLngBounds();
        loc = new this.google.maps.LatLng(
          this.start_point.lat,
          this.start_point.lng
        );
        bounds.extend(loc);
        loc = new this.google.maps.LatLng(
          this.finish_point.lat,
          this.finish_point.lng
        );
        bounds.extend(loc);
        map.fitBounds(bounds);
        map.panToBounds(bounds);
      });
    },
  },
  computed: {
    google: gmapApi,
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1439px) and (min-width: 1024px) {
  .map {
    width: 600px !important;
  }
}
.map {
  width: 460px;
}
</style>